import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as OutlineStar } from '../myicon/OutlineStar.svg';
import { ReactComponent as FillStar } from '../myicon/FillStar.svg';
import { ReactComponent as StarInMenu } from '../myicon/star_menu.svg';


import TextField from '@mui/material/TextField';
import { IconButton , InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

import { setProductsInMeal } from '../../actions';


import { HiOutlineX } from "react-icons/hi";
import { fetchDataFromDatabase } from '../DataService';
import BackButton from '../Button/BackButton';
import ModalNoMassa from '../Meals/ModalNoMassa';
import ModalOk from '../Meals/ModalAppProductOk';
// import ModalNoMassa from '../InfoModal';

import '../ProductDirectory/ProductSearch.css';

// let productID = [];

const sentence = (string) => {
  return `В 1г - ` + string + 'Ккал';
};

const ProductMassCal = (num1, num2) => {
if (num2 !=null) {
  const result = parseFloat(num1)*parseFloat(num2);
return 'В ' + num2 + 'г - '+ result.toFixed(2) + ' Ккал'
}
return ''
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CustomTextField = styled(TextField)({
  '& .MuiFormHelperText-root': {
      color: 'red', // Применение красного цвета к тексту подсказки
      fontSize: '12px', // Изменение размера шрифта подсказки
      fontStyle: 'italic', // Применение курсива к тексту подсказки
      paddingTop: '5px',
      width: '100%',
  },
});


const AddProductMeal = ({iconMenuClick}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productInMeals = useSelector((state) => state.productInMeal);

    const [data, setData] = useState([]);
    const [data_meals, setDataMeals] = useState(productInMeals);
    const [Calorie, setCalorie] = useState(0);

    const [inputValue, setInputValue] = useState('');
    const [inputValueMassa, setInputValueMassa] = useState('');
    const [Mass, setMass] = useState(0);
    const [starredItems, setStarredItems] = useState(data.map(() => false));
    const [isPlaceholder, setPlaceholder] = useState('масса в граммах');
    const [isInformationText, setInformationText] = useState('Избранные продукты');

    const [isModalNoMassaOpen, setModalNoMassaOpen] = useState(false);
    const [isModalOk, setModalOk] = useState(false);
    
    const id_user = useSelector((state) => state.userId);


    useEffect(() => {
        setInputValue('');
        setData([]);
        setMass(0);
        setInformationText('Найденные продукты');
        setPlaceholder('продукт/блюдо');
    }, []);

  useEffect(() => {
    console.log('Обновленный data_meals:', data_meals);
    dispatch(setProductsInMeal (data_meals));
  }, [data_meals]);
  
  useEffect(() => {
      const fetchData = async () => {
        try {
          const url = 'https://www.foodandsports.ru/api/favoritesproductsgetalluserid.php'; 
          const jsonData = JSON.stringify({ id_user: id_user });
          const favoriteProductsData = await fetchDataFromDatabase(url, jsonData);
          const productID = favoriteProductsData.map(product => product.id_productfood);
          const newStarredItems = data.map(item => productID.includes(item.id));
          setStarredItems(newStarredItems);
          // console.log(productID); 
  
        } catch (error) {
          console.error(error);
      }
      };
      fetchData(); 
  }, [data]);
  
  const handleStarClick = async (index) => {
  const newStarredItems = [...starredItems];
  newStarredItems[index] = !newStarredItems[index];
  setStarredItems(newStarredItems);
  
      // Отправка данных на сервер через API
      try {

        if (newStarredItems[index]) { // Добавление продукта в избранные
            const url = 'https://www.foodandsports.ru/api/favoritesproductsadd.php'; 
            const jsonData = JSON.stringify({ id_food: data[index].id, id_user: id_user});
            await fetchDataFromDatabase(url, jsonData);
        }

        if (!newStarredItems[index]) { // Удаление продукта из избранного
          console.log("555");
            const url = 'https://www.foodandsports.ru/api/favoritesproductsdel.php'; 
            const jsonData = JSON.stringify({ id_food: data[index].id, id_user: id_user});
            await fetchDataFromDatabase(url, jsonData);
       }
      } catch (error) {
          console.error('Произошла ошибка:', error);
      }  
  };

  const handleInputChangeMassa = async (event) => {
    const value_massa = event.target.value;
    if (/^\d*$/.test(value_massa)) {
      setInputValueMassa(value_massa);
      setMass(value_massa);
    }
  }
    const handleInputChange = async (event) => {
      const value = event.target.value;

      if (value === '') {
        clearInput();
        return;
      }
          // console.log('Да!')
          setInputValue(value); 
        // console.log('value - ' + value)

        try {
          const url = `https://www.foodandsports.ru/api/search_product.php?term=${value}`;
          const response = await fetch(url);
          const jsonData = await response.json();
          setData(jsonData);

      } catch (error) {
        console.error('Error fetching meal data:', error);
        }
    };
    const clearInput = () => {
        setInputValue('');

    };

    const clearInputMassa = () => {
      setInputValueMassa('');
      setMass(0);
    }
  
  
  const handleItemClick = async (index) => {

    if (Mass!=0) {
      const sumcalorie= Mass * data[index].calorie;
      setDataMeals(prevMeals => [...prevMeals, {...data[index],  mass: Mass, sumcalorie:sumcalorie}]);
      setModalOk(true)
      setTimeout(() => {
        setModalOk(false);
      }, 1000);
      
      setTimeout(() => {
        navigate(-1);
      }, 1000);

    } else {
      setModalNoMassaOpen(true)
      setTimeout(() => {
        setModalNoMassaOpen(false);
      }, 1000);
    }
  };
  
  const handleStarInMenuClick = () => {
    navigate('/mainmenu/menumeals/creatingmeal/addproductmealfavorite');
  };


return (
  <div className="app-container">
    {isModalNoMassaOpen && <ModalNoMassa/>}
    {isModalOk && <ModalOk/>}
   

<header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">Добавить продукт</p>
            <StarInMenu style={{ marginLeft: 'auto', paddingRight: '15px'}} onClick={handleStarInMenuClick} />
        </div>    
</header>
    
<main>
    
<div>

<CustomTextField
  className="input-style-productsearch"
  value={inputValue} 
  // helperText={isHelperText}
  onChange={handleInputChange} 
  placeholder={isPlaceholder}
  InputProps={{
      endAdornment: (
        <React.Fragment>
            <IconButton onClick={clearInput}>
              <HiOutlineX />
            </IconButton>       
        </React.Fragment>  
      )
  }}
/>

<CustomTextField
    className="input-style-productsearch"
    style={{ marginTop: '15px' }}
    value={inputValueMassa} 
    // helperText={isHelperText}
    onChange={handleInputChangeMassa} 
    placeholder={'масса продукта/блюда в граммах'}
    InputProps={{
        endAdornment: (
            <React.Fragment>
                <IconButton onClick={clearInputMassa}>
                    <HiOutlineX />
                </IconButton>
            </React.Fragment>  
        )
    }}
/>  
</div>



<div className="informationText">{isInformationText}</div>

<ul className="container-item-productsearch">

{data.map((item, index) => (
<div key={index} className="item-productsearch" onClick={() => handleItemClick(index)}>
<div className="item-name-productsearch">
  <div className="item-name-text-productsearch">
    {capitalizeFirstLetter(item.name)}
  </div>
  <div className="item-name-icon-productsearch" onClick={(e) => {
    e.stopPropagation(); // Остановить всплытие события
       if (!e.target.closest('.itemNameStyleIcon')) {
         handleStarClick(index);
          }
   }}>
 
  {starredItems[index] ? <FillStar /> : <OutlineStar />}
  
  </div>   
</div>
<div className="item-calorie-productsearch" >
  <div>{sentence(item.calorie)}</div>
</div>
<div className="item-calorie-bold-productsearch">
  <div>{Mass !== 0 && ProductMassCal(item.calorie, Mass)}</div>
</div>
</div>
))}
</ul>

</main>  
    

</div>
);
};
export default AddProductMeal;