import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InputMask from 'react-input-mask';
import { isValid, parse, format } from 'date-fns';
import MaskedInput from 'react-text-mask';



import { ReactComponent as OutlineStar } from '../myicon/OutlineStar.svg';
import { ReactComponent as FillStar } from '../myicon/FillStar.svg';
import { ReactComponent as NoFavoritesIcon } from '../myicon/no_favorites_icon.svg';

import { ReactComponent as DelCircle } from '../myicon/del_circle.svg';

import TextField from '@mui/material/TextField';
import { IconButton , InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

import { setProductsInMeal } from '../../actions';

import { HiOutlineX } from "react-icons/hi";
import { fetchDataFromDatabase } from '../DataService';
import BackButton from '../Button/BackButton';
import '../ProductDirectory/ProductSearch.css';
import ButtonAddProduct from './ButtonAddProduct';
import ButtonAddMeal from '../Button/ButtonAddMeal';
import ButtonCreatingMeal from './ButtonCreatingMeal';




function CreatingMeal ({iconMenuClick}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState([])

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Преобразуем в формат 'yyyy-mm-dd'
    
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const [inputValueTime, setInputValueTime] = useState(formatTime(new Date()));
    const [inputValueTypemeal, setInputValueTypemeal] = useState('Перекус');
    const [InformationText, setInformationText] = useState('Продукты/блюда');
    const [PageTitle, setPageTitle] = useState('Создать прием пищи');
    const [date, setDate] = useState('');
    const [isValidDate, setIsValidDate] = useState(null);
    const [isValidTime, setIsValidTime] = useState(null);
    
    

    const id_user = useSelector((state) => state.userId);
    const productInMeals = useSelector((state) => state.productInMeal);


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

    useEffect(() => {
        // Получаем текущую дату в формате дд/мм/гггг
        const currentDate = format(new Date(), 'dd/MM/yyyy');
        setDate(currentDate);
        setIsValidDate(null); // Сбрасываем состояние валидации
        console.log('productInMeals - ', productInMeals)
        setData(productInMeals);
      }, []); 

      useEffect(() => {
        dispatch(setProductsInMeal (data));
      }, [data]); 

  const handleChange = (event) => {
    const inputDate = event.target.value;
    setDate(inputDate);

    // Проверка и валидация введенной даты
    if (isValidDateFormat(inputDate)) {
      const parsedDate = parseDate(inputDate);
      setIsValidDate(isValid(parsedDate));
    } else {
      setIsValidDate(false);
    }
  };

  const isValidDateFormat = (dateString) => {
    // Проверяем формат дд/мм/гггг
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(dateString);
  };

  const parseDate = (dateString) => {
    // Парсим дату, используя формат дд/мм/гггг
    return parse(dateString, 'dd/MM/yyyy', new Date());
  };


  const validateTime = (time) => {
    // Регулярное выражение для проверки формата чч:мм
    const regex = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):([0-5][0-9])$/;
    return regex.test(time);
  };

  const handleTimeChange = (e) => {
    const newValue = e.target.value;
    setInputValueTime(newValue);
    setIsValidTime(validateTime(newValue));
  };


  const handleClick_AddProduct = () => {
    navigate('/mainmenu/menumeals/creatingmeal/addproductmeal');
  };

  const handleClick_CreatingMeal = async () => {
    // try {
    //     console.log('Добавление meal')
    //     const url = 'https://www.foodandsports.ru/api/mealEntryApi.php'; 
    //     const jsonData = JSON.stringify({ id_user: id_user, date:isDate, time:isTime, type:isTypeMeal, calorie:SumCaloria.toFixed(2) });
    //     const id_meal = await fetchDataFromDatabase(url, jsonData);
    //     setIsIdMeal(id_meal);
  
    //   } catch (error) {
    //     console.error(error);
    //   }
  };

  const handleItemDelClick = async (index) => {
    // console.log(data[index]);

    setData(prevData => {
        // Создаем новый массив, исключая элемент по переданному индексу
        const newData = prevData.filter((_, i) => i !== index);
        return newData;
      });
  };

return (
  <div className="app-container">
  

<header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">{PageTitle}</p>
            
            
        </div>    
</header>
    
<main>
    
<div style={{ marginBottom: '10px'}}>
<div>
<MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} // Маска для даты
                value={date}
                onChange={handleChange}
                placeholder="ДД/ММ/ГГГГ"
                style={{ padding: '10px', width: '300px', fontSize: '16px' }}
            />
      {isValidDate !== null && (
  <div>
    {!isValidDate ? (
      <span style={{ color: 'red' }}>Дата некорректна</span>
    ) : null // Если дата корректна, ничего не выводится
    }
  </div>
)}
    </div>
     
</div>

<div>
<div style={{ marginBottom: '10px' }}>
        <MaskedInput
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                value={inputValueTime}
                onChange={handleTimeChange}
                style={{ padding: '10px', width: '300px', fontSize: '16px' }}
                placeholder="hh:mm"
            />
      </div>
      {isValidTime !== null && (
        <div>
          {!isValidTime ? (
            <span style={{ color: 'red' }}>Время некорректно (формат чч:мм)</span>
          ) : null
          }
        </div>
      )}
</div>

<div style={{ marginBottom: '10px'}}>
      <input
        type="text"
        value={inputValueTypemeal}
        onChange={(e) => setInputValueTypemeal(e.target.value)}
        style={{ padding: '10px', width: '300px', fontSize: '16px'}} 
      />
</div>

<div style={{ marginBottom: '5px'}}>{InformationText}</div>

<ul className="container-item-productsearch">

{data.map((item, index) => (
<div key={index} className="item-productsearch">
<div className="item-name-productsearch">
  <div className="item-name-text-productsearch">
    {capitalizeFirstLetter(item.name)}
  </div>
  <div className="item-name-icon-productsearch" onClick={(e) => {
    e.stopPropagation(); // Остановить всплытие события
       if (!e.target.closest('.itemNameStyleIcon')) {
        //  handleStarClick(index);
          }
   }}>
 
 <DelCircle onClick={() => handleItemDelClick(index)}/>
  
  </div>   
</div>
<div className="item-calorie-productsearch" >
  {/* <div>{sentence(item.calorie)}</div> */}
  <div> {item.mass}гр - {item.sumcalorie.toFixed(1)} Ккал</div>
</div>
<div className="item-calorie-bold-productsearch">
  {/* <div>{Mass !== 0 && ProductMassCal(item.calorie, Mass)}</div> */}
</div>
</div>
))}
</ul>




</main>  

<footer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

<div onClick={handleClick_AddProduct} style={{ marginBottom: '10px' }}>
    <ButtonAddProduct />
</div>

<div onClick={handleClick_CreatingMeal} style={{ marginBottom: '10px' }}>
        <ButtonCreatingMeal />
      </div>
</footer>

</div>
);
};
export default CreatingMeal;