import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ButtonMeals from '../Button/MenuMeals/ButtonMeals';
import ButtonCreatemeals from '../Button/MenuMeals/ButtonCreatemeals';
import ButtonPlan from '../Button/MenuMeals/ButtonPlan';

import { setProductsInMeal } from '../../actions';

import BottomMenu from '../Button/BottomMenu';
import BackButton from '../Button/BackButton';
import './MenuMeals.css';

// Стили для Header и содержимого
const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '10px',
  };

const contentStyle = {
    width: '100%', // Занимает всю ширину
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center', // Центрируем элементы по горизонтали
    alignItems: 'left', // Центрируем элементы по горизонтали
  };

const textStyle = {
    textAlign: 'center', // Центрируем текст
    fontSize: '24px',    // Размер шрифта 12
    margin: '0',         // Убираем отступы
    fontWeight: '500',
    // paddingBottom: '5px',
  };


function MenuMeals() {

  const navigate = useNavigate();
  const userId = useSelector((state) => state.userId);
  const dispatch = useDispatch();

  const handleClick_Createmeals = () => {
    navigate('/mainmenu/menumeals/creatingmeal');
  };

  useEffect(() => {
    dispatch(setProductsInMeal ([]));
  }, []); 


    return (
        <div className="app-container">

    <header style={headerStyle}>
        <div style={contentStyle}>
            <BackButton />
            <p style={textStyle}>Приемы пищи</p>
        </div>
     </header>

     <main>
        <div style={{rowGap:'15px', gap: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', padding:'5px'}}>
            <ButtonMeals />
            <ButtonCreatemeals onClick={handleClick_Createmeals} />
            <ButtonPlan />
        </div>            
    </main>


     <footer>
           <BottomMenu/>
            
     </footer>

        </div>
        );

}

export default MenuMeals;